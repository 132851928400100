<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();

defineProps<{
	enabled?: boolean;
	image: string;
	type?: Promotion["type"];
	text?: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();
const handleClick = () => {
	emit("click");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 292,
			height: 380,
			src: image,
			format: 'avif',
			loading: 'lazy'
		}"
		width="292px"
		height="380px"
		:class="[`card-${type}`]"
		@click="handleClick"
	>
		<template #top>
			<div class="logo">
				<MHomeTitleFortuneWheelAnimation />
			</div>
			<div class="text">
				<AText variant="tanta" as="h3" :modifiers="['uppercase', 'center', 'nowrap']" class="title">
					{{ t("Rewards every day!") }}
				</AText>
				<AText variant="toledo" as="p" :modifiers="['bold', 'center']" class="subtitle">{{ text }}</AText>
			</div>
		</template>
		<template #bottom>
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/leave-1.png"
				width="270"
				height="271"
				class="leave leave-1"
				alt="leaves"
				format="avif"
				loading="lazy"
			/>
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/leave-2.png"
				width="249"
				height="262"
				class="leave leave-2"
				alt="leaves"
				format="avif"
				loading="lazy"
			/>
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/leave-3.png"
				width="220"
				height="216"
				class="leave leave-3"
				alt="leaves"
				format="avif"
				loading="lazy"
			/>
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/leave-4.png"
				width="209"
				height="224"
				class="leave leave-4"
				alt="leaves"
				format="avif"
				loading="lazy"
			/>

			<AButton variant="primary" size="xl" class="foot" data-tid="promo-wheel-play-now">
				<AText variant="turin" :modifiers="['medium']"> {{ t("Play Now!") }} </AText>
			</AButton>
		</template>
		<div class="wheel-wrapper">
			<MWheelOfFortuneAnimated size="250px" />
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/coin.png"
				width="89"
				height="93"
				class="coins"
				alt="coins"
				format="avif"
				loading="lazy"
			/>
		</div>
	</ACard>
</template>

<style scoped lang="scss">
.card-dailyWheel.card {
	overflow: visible;
}
.logo {
	margin: 52px 0 -79px;
	:deep(.frame) {
		margin: -60px -15% 0;
		width: 130%;
		@include media-breakpoint-down(md) {
			margin: -60px -24% 0;
			width: 150%;
		}
		.leaves {
			top: -10px;
			width: 115px;
		}
		.title {
			top: 20px;
		}
	}
}
.text {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	margin-top: 62px;
}

.title {
	text-shadow:
		0 4px 6px rgba(var(--corlu-rgb), 0.15),
		0 10px 15px rgba(var(--corlu-rgb), 0.21);
	line-height: 26px;
}

.subtitle {
	text-shadow:
		0 4px 6px rgba(var(--corlu-rgb), 0.15),
		0 10px 15px rgba(var(--corlu-rgb), 0.21);
	line-height: 20px;
}
.wheel-wrapper {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 71px);
	z-index: 3;
}
.foot {
	width: 100%;
	z-index: 12;
}
.coins {
	position: absolute;
	height: auto;
	z-index: 3;
	width: 62px;
	bottom: 167px;
	left: -9px;
}
.leave {
	position: absolute;
	height: auto;
	z-index: 2;
	&-1 {
		bottom: 4px;
		left: 26px;
		width: 86px;
		z-index: 3;
		animation: leave 5s 4s ease-in-out infinite reverse;
	}
	&-2 {
		left: -18px;
		width: 111px;
		bottom: 1px;
		animation: leave 3s 4s ease-in-out infinite;
	}
	&-3 {
		bottom: 5px;
		right: 20px;
		width: 92px;
		z-index: 3;
		animation: leave 4s 4s ease-in-out infinite reverse;
	}
	&-4 {
		bottom: 8px;
		right: -18px;
		width: 93px;
		animation: leave 3s 4s ease-in-out infinite;
	}
}

@keyframes leave {
	40% {
		transform: rotate(-5deg) scale(1.1);
	}
}
</style>
